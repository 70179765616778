<template>
    <v-card class="mt-10" tile flat>
            <v-card-title >
               <h3 class="zamu-heading admin-title admin-section-title"> FAQs</h3>
                <v-spacer></v-spacer>
            
            </v-card-title>
            <v-card-text>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="admin-search"
                outlined
                dense
            ></v-text-field>
            </v-card-text>
              <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
            <div v-if="posts === null">
                <h3>No FAQ posts found</h3>

            </div>
            <v-data-table
                :headers="headers"
                :items="postData"
                :search="search"

                class=" users-table"
                v-else
              >
            <template v-slot:item.title="{ item }" width="20%">
              <span class="main-item"> {{ item.title }}</span>
            </template>
             <template v-slot:item.status="{ item }">
              <span class="" :class="item.status === 1 ? 'active' : 'deactivated'"> {{ item.status === 1 ? 'active' : 'deactivated' }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat class="text-right mt-14" width="100%">
                  <v-spacer></v-spacer>
                   <v-btn :color="`${zamuPurple2}`" href="/admin/new-FAQ" dark class="mb-2 mr-4">
                        New FAQ
                    </v-btn>
                    <v-btn :color="`${zamuPurple2}`" href="/admin/FAQ-categories" dark class="mb-2 mr-4">
                        FAQ categories
                    </v-btn>
                
                  <v-dialog v-model="dialogDelete" max-width="550px">
                    <v-card>
                      <v-card-title class="">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <v-pagination v-if="posts !== null" v-model="currentPage"  class="zamu-pagination my-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>
      </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'FAQs',
     components: {
    },
    mixins: [coreMixin, colorMixin],

    data() {
        return {
            posts: null,
            search: '',
            dialog: false,
            dialogDelete: false,
            currentPage: 1,
            deletedID: null,
             notification: '',
            actionClass: '',
            headers: [
                {
                    text: 'Title',
                    align: 'start',
                    sortable: false,
                    value: 'title',
                },
                { text: 'Status', value: 'status' },
                { text: 'Date Created ', value: 'created_at' },
                { text: 'Date Updated', value: 'updated_at' },
                { text: 'Actions', value: 'actions', sortable: false }
                ],
        editedIndex: -1,
        };
    },
     computed: {
         ...mapGetters(['getUpdateStatus']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
        postData () {
            return this.posts !== null ? this.posts.data : null;
        },
        pagination () {
            let data = null;
            if (this.posts !== null) {
                 data = {
                    total: this.posts.total,
                    perPage: this.posts.perPage,
                    page: this.posts.page,
                    lastPage: this.posts.lastPage,
                };
            }
            return data;

        },
    },
    watch: {
        getUpdateStatus(status) {
            if(status) {
                this.retrieveFAQs();
            }
        },
        async currentPage() {
              await this.retrieveFAQs();

        },
    },
    mounted() {
        this.retrieveFAQs();
    },
    methods: {
    ...mapMutations(['setUpdateStatus']),

        async retrieveFAQs() {

            this.loading = true;
            this.requested = true;

            const payload = {
              page: this.currentPage,
            };

            const endpoint = `FAQs/posts`;

            const fullPayload = {
                app: this.apiUrl,
                params: payload,
                endpoint,
            };

            try {
                const response = await this.axiosGetRequest(fullPayload);
                const posts = response.status === 200 ? response.data : null;
                this.posts = posts;
                this.loading = false;
                this.setUpdateStatus(false);
                return posts;
            } catch (error) {
                this.notification = 'Failed to fetch FAQs data. Kindly try again or contact tech support';
                this.loading = false;
            }
        },

        editItem (item) {
            return this.$router.push({ name: 'singleFAQ', params: { id: item.id } });
         
        },

      deleteItem (item) {
        this.deletedID = item.id;
        this.dialogDelete = true
      },

     async deleteItemConfirm () {

              const payload = {
                    status: 0,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: `FAQs/deactivate/${this.deletedID}`,
                };
                  try {
                    const res = await this.axiosPutRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.data ? 'FAQ post deactivated successful' : 'FAQ post failed to deactivate';
                    this.actionClass = res.data  ? 'success' : 'error';
                    if(res.data) {
                      this.retrieveMembers()

                    }
                  

                } catch (error) {
                    this.notification = 'FAQ post failed to deactivate. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    }

}
</script>

<style>

</style>